'use strict'; // Открытие/закрытие основного меню

var mainMenu = document.querySelector('.js-main-nav');
var showMainMenu = document.querySelector('.js-show-menu');
var subListFirst = document.querySelector('.js-sub-nav-1');
var subListSecond = document.querySelector('.js-sub-nav-2');
showMainMenu.addEventListener('click', function (evt) {
  evt.preventDefault();

  if (mainMenu.classList.contains('main-nav--closed')) {
    mainMenu.classList.add('main-nav--opened');
    mainMenu.classList.remove('main-nav--closed'); // subListFirst.classList.add('show-list');
    // subListSecond.classList.add('show-list');
  } else {
    mainMenu.classList.remove('main-nav--opened');
    mainMenu.classList.add('main-nav--closed'); // subListFirst.classList.remove('show-list');
    // subListSecond.classList.remove('show-list');
  }
}); // Форма поиска

var showSearch = document.querySelector('.js-show-search');
var searchForm = document.querySelector('.js-search');
var searchInput = searchForm.querySelector('[name=search]');
showSearch.addEventListener('click', function (evt) {
  evt.preventDefault();
  this.classList.toggle('main-header__show-search--active');
  searchForm.classList.toggle('show');
  searchInput.focus();
}); // Форма обратной связи

var btnShowFeedback = document.querySelectorAll('.js-btn-show-modal-feedback');
var modalOverlay = document.querySelector('.js-modal-overlay');
var feedbackPopup = document.querySelector('.js-modal-feedback');
var form = feedbackPopup.querySelector('.js-modal-form');
var nameField = feedbackPopup.querySelector('[name=imyarek]');
var emailField = feedbackPopup.querySelector('[name=pochta]');
var commentField = feedbackPopup.querySelector('[name=commento]');
var isStorageSupport = true;
var storageName = '';
var storageEmail = '';
var popupClose = document.querySelectorAll('.modal__close');

try {
  storageName = localStorage.getItem('name');
  storageEmail = localStorage.getItem('email');
} catch (err) {
  isStorageSupport = false;
}

for (var i = 0; i < btnShowFeedback.length; i++) {
  btnShowFeedback[i].addEventListener('click', function (evt) {
    evt.preventDefault();
    feedbackPopup.classList.add('modal-show');
    modalOverlay.classList.add('modal-overlay');

    if (storageName && !emailField) {
      nameField.value = storageName;
      emailField.focus();
    } else if (storageEmail && storageName) {
      nameField.value = storageName;
      emailField.value = storageEmail;
      commentField.focus();
    } else {
      nameField.focus();
    }
  });
}

form.addEventListener('submit', function (evt) {
  if (!nameField.value || !emailField.value || !commentField.value) {
    evt.preventDefault();
    feedbackPopup.classList.add('modal-error');
  } else {
    if (isStorageSupport) {
      localStorage.setItem('name', nameField.value);
      localStorage.setItem('email', emailField.value);
    }
  }
});
window.addEventListener('keydown', function (evt) {
  if (evt.keyCode === 27) {
    evt.preventDefault();

    if (feedbackPopup.classList.contains('modal-show')) {
      feedbackPopup.classList.remove('modal-show');
      feedbackPopup.classList.remove('modal-error');
      modalOverlay.classList.remove('modal-overlay');
    }
  }
}); // Кнопка закрытия для всех модальных окон

for (var _i = 0; _i < popupClose.length; _i++) {
  popupClose[_i].addEventListener('click', function (evt) {
    evt.preventDefault();
    this.parentNode.classList.remove('modal-show');
    modalOverlay.classList.remove('modal-overlay');
    console.log('Закрыл элемент ' + this.parentNode.className);
  });
} // Unique ID


var formInput = document.querySelectorAll('.js-input');
var formLabel = document.querySelectorAll('.js-label');

var _loop = function _loop(_i2) {
  formInput[_i2].setAttribute('id', _i2 + 1);

  formLabel[_i2].setAttribute('for', _i2 + 1);

  formInput[_i2].addEventListener('change', function (evt) {
    evt.preventDefault(); //console.log(this.value);

    /*    if (this.value === '') {
          formLabel[i].classList.remove('fields__label--active');
        } else {
          formLabel[i].classList.add('fields__label--active');
        }*/

    this.value === '' ? formLabel[_i2].classList.remove('fields__label--active') : formLabel[_i2].classList.add('fields__label--active');
  });
};

for (var _i2 = 0; _i2 < formInput.length; _i2++) {
  _loop(_i2);
} // jquery


$(function () {
  //$('body').css('background-color', 'orange');
  $('.js-slider-examples').slick({
    lazyLoad: 'progressive',
    centerMode: true,
    centerPadding: '100px',
    dots: true,
    infinite: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1
  });
  $('.js-slider-manufacture').slick({
    lazyLoad: 'progressive',
    centerMode: true,
    centerPadding: '100px',
    dots: true,
    infinite: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1
  });
  $('.js-slider-offer').slick({
    fade: true,
    //lazyLoad: 'ondemand',
    //centerMode: true,
    //centerPadding: '40px',
    //dots: true,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1
  });
  $('.js-slider-rubricator').slick({
    //fade: true,
    //centerMode: true,
    //centerPadding: '50px',
    dots: true,
    //variableWidth: true,
    infinite: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 570,
      settings: {
        centerMode: true,
        centerPadding: '60px',
        variableWidth: false,
        slidesToShow: 2
      }
    }, {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
      }
    }]
  });
});